/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import HeroEvent from 'components/shared/HeroEvent'
import Breadcrumb from 'components/shared/Breadcrumb'
import GravityForm from 'components/GravityForm'
import styled from 'styled-components'
import ShadowBox from '../components/shared/ShadowBox'
import ButtonDefault from 'components/elements/ButtonDefault'
import parse from 'html-react-parser'
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...KerstEventFrag
    }
  }
`

const Info = styled.section`
  & h2 {
    font-size: ${(props) => props.theme.font.size.xl};
    font-weight: ${(props) => props.theme.font.weight.xl};
  }

  & img {
    border-radius: 20px;
    width: 100%;
  }
`

const FormWrapper = styled.div`
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  max-width: 760px;
  margin: 0 auto;
`

const StyledForm = styled.section`
  & h2 {
    font-size: ${(props) => props.theme.font.size.xl};
    font-weight: ${(props) => props.theme.font.weight.xl};
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, acf, yoast_meta: yoast },
  },
  pageContext,
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{
          url:
            acf.banner.image.localFile &&
            acf.banner.image.localFile.childImageSharp.openGraph.src,
        }}
      />

      <HeroEvent left content={acf.banner} event className="mb-lg-3" />

      <Breadcrumb data={title} className="py-2" pageContext={pageContext} />

      <Info>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-6 pr-lg-5 mb-5 mb-lg-0">
              <h2>{acf.info.title}</h2>
              <ParseContent
                content={acf.info.description}
                className="mb-lg-3"
              />
              <ButtonDefault icon="arrow" to={acf.info.link.url}>
                {acf.info.link.title}
              </ButtonDefault>
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0">
              <ShadowBox
                content={acf.info}
                extraInfo={acf.banner}
                reverse
                className="mb-lg-5"
              />
            </div>
          </div>
        </div>
      </Info>

      <StyledForm id="inschrijven">
        <div className="container py-5 px-lg-5 px-4">
          <FormWrapper className="p-5">
            <h2 className="mb-4 mb-lg-5">{acf.formgroup.title}</h2>
            <GravityForm enableConsent id={17} split={false} />
          </FormWrapper>
        </div>
      </StyledForm>

      <Info>
        <div className="container py-5">
          <div className="row py-lg-5">
            <div className="col-lg-6 pr-lg-5">
              <h2>{acf.text_image.title}</h2>
              <ParseContent
                content={acf.text_image.description}
                className="pr-lg-5"
              />
            </div>
            <div className="col-lg-6">
              <Plaatjie
                loading="eager"
                image={acf.text_image.image}
                alt={acf.text_image.title}
              />
            </div>
          </div>
        </div>
      </Info>
    </Layout>
  )
}

export default PageTemplate
